import * as React from "react";

import Layout from "../components/layout";

const PrivacyPolicy = () => {
  return (
    <Layout darkFooter>
      <section className="pt-8 pt-md-11 pb-8 pb-md-14">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h1 className="display-4 mb-2">Politique de confidentialité</h1>

              <p className="fs-lg text-gray-700 mb-md-0">
                mise à jour 30/09/2021
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr className="my-6 my-md-8" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8">
              <h3 className="mb-5">ARTICLE 1. PRÉAMBULE</h3>

              <p className="text-gray-800">
                Cette politique de confidentialité s'applique au site&nbsp;:
                APPSR.FR <br />
                <br />
              </p>
              <p className="text-gray-800">
                La présente politique de confidentialité a pour but d'exposer
                aux utilisateurs du site&nbsp;:
              </p>
              <ul className="text-gray-800">
                <li>
                  La manière dont sont collectées et traitées leurs données à
                  caractère personnel. Doivent être considérées comme données
                  personnelles toutes les données étant susceptibles
                  d'identifier un utilisateur. Il s'agit notamment du prénom et
                  du nom, de l'âge, de l'adresse postale, l'adresse mail, la
                  localisation de l'utilisateur ou encore son adresse IP
                </li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  Quels sont les droits des utilisateurs concernant ces
                  données&nbsp;;
                </li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  Qui est responsable du traitement des données à caractère
                  personnel collectées et traitées
                </li>
              </ul>
              <ul className="text-gray-800">
                <li>A qui ces données sont transmises</li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  Eventuellement, la politique du site en matière de fichiers
                  "cookies".
                </li>
              </ul>
              <p className="text-gray-800">
                Cette politique de confidentialité complète les mentions légales
                et les Conditions Générales d'Utilisation que les utilisateurs
                peuvent consulter à l'adresse ci-après&nbsp;:
              </p>
              <p className="text-gray-800">https://www.appsr.fr/terms/</p>
              <h3 className="mb-5">
                ARTICLE 2. PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE
                TRAITEMENT DE DONNÉES
              </h3>
              <ul className="text-gray-800">
                <li>
                  Licéité, loyauté et transparence&nbsp;: les données ne peuvent
                  être collectées et traitées qu'avec le consentement de
                  l'utilisateur propriétaire des données. A chaque fois que des
                  données à caractère personnel seront collectées, il sera
                  indiqué à l'utilisateur que ses données sont collectées, et
                  pour quelles raisons ses données sont collectées
                </li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  Finalités limitées&nbsp;: la collecte et le traitement des
                  données sont exécutés pour répondre à un ou plusieurs
                  objectifs déterminés dans les présentes conditions générales
                  d'utilisation
                </li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  Minimisation de la collecte et du traitement des
                  données&nbsp;: seules les données nécessaires à la bonne
                  exécution des objectifs poursuivis par le site sont collectées
                </li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  Conservation des données réduites dans le temps&nbsp;: les
                  données sont conservées pour une durée limitée, dont
                  l'utilisateur est informé. Lorsque cette information ne peut
                  pas être communiquée, l'utilisateur est informé des critères
                  utilisés pour déterminer la durée de conservation
                </li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  Intégrité et confidentialité des données collectées et
                  traitées&nbsp;: le responsable du traitement des données
                  s'engage à garantir l'intégrité et la confidentialité des
                  données collectées.
                  <br />
                  <br />
                </li>
              </ul>
              <p className="text-gray-800">
                Afin d'être licites, et ce conformément aux exigences de
                l'article 6 du règlement européen 2016/679, la collecte et le
                traitement des données à caractère personnel ne pourront
                intervenir que s'ils respectent au moins l'une des conditions
                ci-après énumérées
              </p>
              <ul className="text-gray-800">
                <li>L'utilisateur a expressément consenti au traitement</li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  Le traitement est nécessaire à la bonne exécution d'un contrat
                </li>
              </ul>
              <ul className="text-gray-800">
                <li>Le traitement répond à une obligation légale</li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  Le traitement s'explique par une nécessité liée à la
                  sauvegarde des intérêts vitaux de la personne concernée ou
                  d'une autre personne physique
                </li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  Le traitement peut s'expliquer par une nécessité liée à
                  l'exécution d'une mission d'intérêt public ou qui relève de
                  l'exercice de l'autorité publique
                </li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  Le traitement et la collecte des données à caractère personnel
                  sont nécessaires aux fins des intérêts légitimes et privés
                  poursuivis par le responsable du traitement ou par un tiers.
                </li>
              </ul>
              <p className="text-gray-800">
                <h3 className="mb-5">
                  ARTICLE 3. DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET
                  TRAITÉES DANS LE CADRE DE LA NAVIGATION SUR LE SITE
                </h3>
              </p>
              <p style={{ paddingLeft: "30px" }}>
                <strong>A. </strong>DONNÉES COLLECTÉES ET TRAITÉES ET MODE DE
                COLLECTE
              </p>
              <p className="text-gray-800">
                Les données à caractère personnel collectées sur le site APPSR
                sont les suivantes&nbsp;:
              </p>
              <p style={{ paddingLeft: "30px" }} className="text-gray-800">
                - Nom;
                <br />- Courriel
              </p>
              <p className="text-gray-800">
                Ces données sont collectées lorsque l'utilisateur effectue l'une
                des opérations suivantes sur le site&nbsp;:
              </p>
              <p style={{ paddingLeft: "30px" }} className="text-gray-800">
                Lorsque l'utilisateur utilise le formulaire de contact pour
                faire une demande.
              </p>
              <p className="text-gray-800">
                Le responsable du traitement conservera dans ses systèmes
                informatiques du site et dans des conditions raisonnables de
                sécurité l'ensemble des données collectées pour une durée
                de&nbsp;: cookies d'analyse de visite (13 mois).
              </p>
              <p className="text-gray-800">
                La collecte et le traitement des données répondent aux finalités
                suivantes&nbsp;:
              </p>
              <p style={{ paddingLeft: "30px" }} className="text-gray-800">
                - Réponse aux demandes envoyées par le biais du formulaire de
                contact.
              </p>
              <p className="text-gray-800">
                Les traitements de données effectués sont fondés sur les bases
                légales suivantes&nbsp;:
              </p>
              <p style={{ paddingLeft: "30px" }} className="text-gray-800">
                - Consentement de l'utilisateur
                <br />- Obligation légale
              </p>
              <p style={{ paddingLeft: "30px" }}>
                <strong>B.</strong> TRANSMISSION DES DONNÉES A DES TIERS
              </p>
              <p className="text-gray-800">
                Les données à caractère personnel collectées par le site ne sont
                transmises à aucun tiers, et ne sont traitées que par l'éditeur
                du site. <br />
                <br />
              </p>
              <p style={{ paddingLeft: "30px" }}>
                <strong>C. </strong>HÉBERGEMENT DES DONNÉES
              </p>
              <p className="text-gray-800">
                Le site APPSR est hébergé par&nbsp;: Netlify, dont le siège est
                situé à l'adresse ci-après&nbsp;:
              </p>
              <p style={{ paddingLeft: "30px" }} className="text-gray-800">
                2325 3rd Street, Suite 296, San Francisco, California 94107
              </p>
              <p className="text-gray-800">
                L'hébergeur peut être contacté à l'adresse suivante&nbsp;:
              </p>
              <p style={{ paddingLeft: "30px" }} className="text-gray-800">
                support@netlify.com
              </p>
              <p className="text-gray-800">
                Les données collectées et traitées par le site sont transférées
                vers le(s) pays suivant(s)&nbsp;:
              </p>
              <p style={{ paddingLeft: "30px" }} className="text-gray-800">
                Etats-Unis, France
              </p>
              <p className="text-gray-800">
                <h3 className="mb-5">
                  ARTICLE 4. RESPONSABLE DU TRAITEMENT DES DONNÉES
                </h3>
              </p>
              <p style={{ paddingLeft: "30px" }}>
                <strong>A. </strong>LE RESPONSABLE DU TRAITEMENT DES DONNÉES
              </p>
              <p className="text-gray-800">
                Le responsable du traitement des données à caractère personnel
                est&nbsp;: Gérard MIHRANYAN.
              </p>
              <p style={{ paddingLeft: "30px" }} className="text-gray-800">
                Le responsable du traitement de données peut être contacté à
                l'adresse hello@appsr.fr
              </p>
              <p className="text-gray-800">
                Le responsable du traitement des données est chargé de
                déterminer les finalités et les moyens mis au service du
                traitement des données à caractère personnel. <br />
                <br />
              </p>
              <p style={{ paddingLeft: "30px" }}>
                <strong>B. </strong>OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES
                DONNÉES
              </p>
              <p className="text-gray-800">
                Le responsable du traitement s'engage à protéger les données à
                caractère personnel collectées, à ne pas les transmettre à des
                tiers sans que l'utilisateur n'en ait été informé et à respecter
                les finalités pour lesquelles ces données ont été collectées.
              </p>
              <p className="text-gray-800">
                Le site dispose d'un certificat SSL afin de garantir que les
                informations et le transfert des données transitant par le site
                sont sécurisés.
              </p>
              <p className="text-gray-800">
                Un certificat SSL ("Secure Socket Layer" Certificate) a pour but
                de sécuriser les données échangées entre l'utilisateur et le
                site.{" "}
              </p>
              <p className="text-gray-800">
                De plus, le responsable du traitement des données s'engage à
                notifier l'utilisateur en cas de rectification ou de suppression
                des données, à moins que cela n'entraîne pour lui des
                formalités, coûts et démarches disproportionnés.
              </p>
              <p className="text-gray-800">
                Dans le cas où l'intégrité, la confidentialité ou la sécurité
                des données à caractère personnel de l'utilisateur est
                compromise, le responsable du traitement s'engage à informer
                l'utilisateur par tout moyen. <br />
                <br />
              </p>
              <h3 className="mb-5">ARTICLE 5. DROITS DE L'UTILISATEUR</h3>
              <p className="text-gray-800">
                Conformément à la réglementation concernant le traitement des
                données à caractère personnel, l'utilisateur possède les droits
                ci-après énumérés.
              </p>
              <p className="text-gray-800">
                Afin que le responsable du traitement des données fasse droit à
                sa demande, l'utilisateur est tenu de lui communiquer&nbsp;: ses
                prénom et nom ainsi que son adresse e-mail.
              </p>
              <p className="text-gray-800">
                Le responsable du traitement des données est tenu de répondre à
                l'utilisateur dans un délai de 30 (trente) jours maximum.
                <br />
                <br />
              </p>
              <p style={{ paddingLeft: "30px" }}>
                <strong>A.</strong> PRÉSENTATION DES DROITS DE L'UTILISATEUR EN
                MATIÈRE DE COLLECTE ET TRAITEMENT DE DONNÉES
              </p>
              <p>
                <em>
                  <br />
                  a. Droit d'accès, de rectification et droit à l'effacement
                </em>
              </p>
              <p className="text-gray-800">
                L'utilisateur peut prendre connaissance, mettre à jour, modifier
                ou demander la suppression des données le concernant, en
                respectant la procédure ci-après énoncée&nbsp;:
              </p>
              <p style={{ paddingLeft: "30px" }} className="text-gray-800">
                L'utilisateur doit envoyer un e-mail au responsable du
                traitement des données personnelles, en précisant l'objet de sa
                demande, à l'adresse e-mail de contact.
                <br />
                <br />
              </p>
              <p>
                <em>b. Droit à la portabilité des données</em>
              </p>
              <p className="text-gray-800">
                L'utilisateur a le droit de demander la portabilité de ses
                données personnelles, détenues par le site, vers un autre site,
                en se conformant à la procédure ci-après&nbsp;:
              </p>
              <p style={{ paddingLeft: "30px" }} className="text-gray-800">
                L'utilisateur doit faire une demande de portabilité de ses
                données personnelles auprès du responsable du traitement des
                données, en envoyant un e-mail à l'adresse prévue ci-dessus.
              </p>
              <p>
                <br />
                <em>
                  c. Droit à la limitation et à l'opposition du traitement des
                  données
                </em>
              </p>
              <p className="text-gray-800">
                L'utilisateur a le droit de demander la limitation ou de
                s'opposer au traitement de ses données par le site, sans que le
                site ne puisse refuser, sauf à démontrer l'existence de motifs
                légitimes et impérieux, pouvant prévaloir sur les intérêts et
                les droits et libertés de l'utilisateur.
              </p>
              <p className="text-gray-800">
                Afin de demander la limitation du traitement de ses données ou
                de formuler une opposition au traitement de ses données,
                l'utilisateur doit suivre la procédure suivante&nbsp;:
              </p>
              <p style={{ paddingLeft: "30px" }} className="text-gray-800">
                L'utilisateur doit faire une demande de limitation au traitement
                de ses données personnelles par e-mail auprès du responsable du
                traitement des données.
                <br />
                <br />
              </p>
              <p>
                <em>
                  d. Droit de ne pas faire l'objet d'une décision fondée
                  exclusivement sur un procédé automatisé
                </em>
              </p>
              <p className="text-gray-800">
                Conformément aux dispositions du règlement 2016/679,
                l'utilisateur a le droit de ne pas faire l'objet d'une décision
                fondée exclusivement sur un procédé automatisé si la décision
                produit des effets juridiques le concernant, ou l'affecte de
                manière significative de façon similaire.
              </p>
              <p>
                <br />
                <em>
                  e. Droit de déterminer le sort des données après la mort
                </em>
              </p>
              <p className="text-gray-800">
                Il est rappelé à l'utilisateur qu'il peut organiser quel doit
                être le devenir de ses données collectées et traitées s'il
                décède, conformément à la loi n°2016-1321 du 7 octobre 2016.
              </p>
              <p>
                <br />
                <em>f. Droit de saisir l'autorité de contrôle compétente</em>
              </p>
              <p className="text-gray-800">
                Dans le cas où le responsable du traitement des données décide
                de ne pas répondre à la demande de l'utilisateur, et que
                l'utilisateur souhaite contester cette décision, ou, s'il pense
                qu'il est porté atteinte à l'un des droits énumérés ci-dessus,
                il est en droit de saisir la CNIL (Commission Nationale de
                l'Informatique et des Libertés, https://www.cnil.fr) ou tout
                juge compétent. <br />
                <br />
              </p>
              <p style={{ paddingLeft: "30px" }}>
                <span style={{ fontWeight: "bold", fontSize: "14.56px" }}>
                  B.
                </span>
                DONNÉES PERSONNELLES DES PERSONNES MINEURES
              </p>
              <p className="text-gray-800">
                Conformément aux dispositions de l'article 8 du règlement
                européen 2016/679 et à la loi Informatique et Libertés, seuls
                les mineurs âgés de 15 ans ou plus peuvent consentir au
                traitement de leurs données personnelles.
              </p>
              <p className="text-gray-800">
                Si l'utilisateur est un mineur de moins de 15 ans, l'accord d'un
                représentant légal sera requis afin que des données à caractère
                personnel puissent être collectées et traitées.
              </p>
              <p className="text-gray-800">
                L'éditeur du site se réserve le droit de vérifier par tout moyen
                que l'utilisateur est âgé de plus de 15 ans, ou qu'il aura
                obtenu l'accord d'un représentant légal avant de naviguer sur le
                site.
              </p>
              <h3 className="mb-5">
                ARTICLE 6. UTILISATION DES FICHIERS "COOKIES"
              </h3>
              <p className="text-gray-800">
                Le site a éventuellement recours aux techniques de "cookies".
              </p>
              <p className="text-gray-800">
                Un "cookie" est un fichier de petite taille (moins de 4 ko),
                stocké par le site sur le disque dur de l'utilisateur, contenant
                des informations relatives aux habitudes de navigation de
                l'utilisateur.
              </p>
              <p className="text-gray-800">
                Ces fichiers lui permettent de traiter des statistiques et des
                informations sur le trafic, de faciliter la navigation et
                d'améliorer le service pour le confort de l'utilisateur.
              </p>
              <p className="text-gray-800">
                Pour l'utilisation de fichiers "cookies" impliquant la
                sauvegarde et l'analyse de données à caractère personnel, le
                consentement de l'utilisateur est nécessairement demandé.
              </p>
              <p className="text-gray-800">
                Ce consentement de l'utilisateur est considéré comme valide pour
                une durée de 6 (six) mois maximum. A l'issue de cette période,
                le site demandera à nouveau l'autorisation de l'utilisateur pour
                enregistrer des fichiers "cookies" sur son disque dur.
              </p>
              <p>
                <br />
                <em>
                  a. Opposition de l'utilisateur à l'utilisation de fichiers
                  "cookies" par le site
                </em>
              </p>
              <p className="text-gray-800">
                Les cookies non essentiels au fonctionnement du site ne sont
                déposés sur le terminal de l'utilisateur qu'après avoir obtenu
                son consentement. L'utilisateur peut retirer son consentement à
                tout moment, de la manière suivante&nbsp;:
              </p>
              <p style={{ paddingLeft: "30px" }} className="text-gray-800">
                Veuillez cliquer sur le bouton "retirer le consentement".
              </p>
              <p className="text-gray-800">
                De manière plus générale, il est porté à la connaissance de
                l'utilisateur qu'il peut s'opposer à l'enregistrement de ces
                fichiers "cookies" en configurant son logiciel de navigation.
              </p>
              <p className="text-gray-800">
                Pour information, l'utilisateur peut trouver aux adresses
                suivantes les démarches à suivre afin de configurer son logiciel
                de navigation pour s'opposer à l'enregistrement des fichiers
                "cookies"&nbsp;:
              </p>
              <ul className="text-gray-800">
                <li>
                  <strong>Chrome</strong>&nbsp;:
                  https://support.google.com/accounts/answer/61416?hl=fr
                </li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  <strong>Firefox</strong>&nbsp;:
                  https://support.mozilla.org/fr/kb/enable-and-disable-cookies-website-preferences
                </li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  <strong>Safari</strong>&nbsp;:
                  http://www.apple.com/legal/privacy/fr-ww/
                </li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  <strong>Internet Explorer</strong>&nbsp;:
                  https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies
                </li>
              </ul>
              <ul className="text-gray-800">
                <li>
                  <strong>Opera</strong>&nbsp;:
                  http://www.opera.com/help/tutorials/security/cookies/
                </li>
              </ul>
              <p className="text-gray-800">
                <br />
                Dans le cas où l'utilisateur décide de désactiver les fichiers
                "cookies", il pourra poursuivre sa navigation sur le site.
                Toutefois, tout dysfonctionnement du site provoqué par cette
                manipulation ne pourrait être considéré comme étant du fait de
                l'éditeur du site.
              </p>
              <h3 className="mb-5">
                ARTICLE 7. CONDITIONS DE MODIFICATION DE LA POLITIQUE DE
                CONFIDENTIALITÉ
              </h3>
              <p className="text-gray-800">
                La présente politique de confidentialité peut être consultée à
                tout moment à l'adresse ci-après indiquée&nbsp;:
              </p>
              <p style={{ paddingLeft: "30px" }} className="text-gray-800">
                https://www.appsr.fr/privacy
              </p>
              <p className="text-gray-800">
                L'éditeur du site se réserve le droit de la modifier afin de
                garantir sa conformité avec le droit en vigueur.
              </p>
              <p className="text-gray-800">
                Par conséquent, l'utilisateur est invité à venir consulter
                régulièrement cette politique de confidentialité afin de se
                tenir informé des derniers changements qui lui seront apportés.
              </p>
              <p className="text-gray-800">
                Il est porté à la connaissance de l'utilisateur que la dernière
                mise à jour de la présente politique de confidentialité est
                intervenue le&nbsp;: 01/09/2021.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <div className="card shadow-light-lg">
                <div className="card-body">
                  <h4>Beoin d'un renseignement ?</h4>
                  <br />

                  <h6 className="fw-bold text-uppercase text-gray-700 mb-2">
                    Par voie postale
                  </h6>

                  <p className="fs-sm mb-5">
                    APPSR
                    <br />
                    120 avenue du général Leclerc
                    <br />
                    75014 Paris
                  </p>

                  <h6 className="fw-bold text-uppercase text-gray-700 mb-2">
                    Par E-mail
                  </h6>

                  <p className="fs-sm mb-0">
                    <a
                      href="mailto:contact@appsr.fr"
                      className="text-reset revert"
                    >
                      rf.rsppa@olleh
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
